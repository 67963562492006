<template>
  <i
    class="fas fa-circle"
    :class="statusClass"
  />
</template>

<script>
import {
  STATUS_ACTIVE,
  STATUS_NOT_INVITED,
  STATUS_PASSWORD_RESET,
  STATUS_INVITATION_SENT
} from '@/data/userStatusValues'

export default {
  name: 'PatientUserStatusIcon',
  props: ['status'], // eslint-disable-line vue/require-prop-types
  computed: {
    statusClass () {
      switch (this.status) {
        case STATUS_ACTIVE:
        case STATUS_PASSWORD_RESET:
        case STATUS_NOT_INVITED:
        case STATUS_INVITATION_SENT:
          return 'status-active'
        default:
          return 'status-muted'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-circle {
    font-size: 12px;
    padding-right: 5px;
    padding-left: 5px;
  }
</style>

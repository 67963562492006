<template>
  <span class="user-container">
    <initials-circle
      class="gray-border-circle initials-circle"
      :inactive="isInactive"
      :first="lastName"
      :second="firstName"
    />
    <slot name="customName">
      <span class="name-container">{{ firstName }} {{ lastName }}</span>
    </slot>
  </span>
</template>

<script>
import InitialsCircle from '@/components/common/InitialsCircle'
export default {
  name: 'UserWithAvatar',
  components: {
    InitialsCircle
  },
  props: ['firstName', 'lastName', 'isInactive'],
  computed: {
  }
}
</script>
<style lang="scss">
.user-container{
  display: flex;
  align-items: center;
  #outer-circle {
    margin: 0 15px 0 0;
    height: 50px;
    width: 50px;
    #inner-circle {
      height: 46px;
      width: 46px;
      top: 94%;
      left: 94%;
      span {
        font-size: 20px;
        width: 22px;
        height: 22px;
      }
      .second-letter {
        bottom: 21px;
        right: 11px;
      }

      .first-letter {
        top: 8px;
        left: 17px;
      }
    }
  }
}
</style>

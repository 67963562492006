<template>
  <i
    class="fas fa-sign-in-alt"
    :class="statusClass"
  />
</template>

<script>
import {
  STATUS_ACTIVE,
  STATUS_PASSWORD_RESET,
  STATUS_INVITATION_SENT
} from '@/data/userStatusValues'

export default {
  name: 'PatientUserSelfLoginIcon',
  props: ['status'], // eslint-disable-line vue/require-prop-types
  computed: {
    statusClass () {
      const activeStatuses = [STATUS_ACTIVE, STATUS_PASSWORD_RESET]
      if (activeStatuses.includes(this.status)) {
        return 'status-active'
      }
      if (this.status === STATUS_INVITATION_SENT) {
        return 'status-invited'
      }
      return 'status-muted'
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-sign-in-alt {
    padding-right: 5px;
    padding-left: 5px;
  }
</style>

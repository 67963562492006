<template>
  <div
    v-tooltip="tooltipData"
    class="btn-group btn-multi-icon"
  >
    <button
      type="button"
      class="btn btn-sm btn-name"
      :class="keepOpenedClass"
      @click="openSubmenu"
    >
      <PatientUserStatusIcon
        :class="keepOpenedClass"
        :status="accountData.status"
      />
      <PatientUserSelfLoginIcon
        :class="keepOpenedClass"
        :status="accountData.status"
      />
    </button>
    <button
      type="button"
      :class="`${keepOpenedClass} ${opened ? 'down' : ''}`"
      class="btn btn-sm dropdown-toggle dropdown-toggle-split"
      @click="openSubmenu"
    />
    <div
      class="dropdown-menu owl-menu"
      :class="{show: opened}"
    >
      <a
        v-for="(item, index) in items"
        :key="index"
        class="dropdown-item"
        href="javascript:void(0)"
        @click="triggerActiveClassAndCallMethod(item.method)"
      >
        {{ item.label }}
      </a>
    </div>
  </div>
</template>

<script>
import { RespondentAccountActions } from '@/mixins/RespondentAccountActions'
import PatientUserSelfLoginIcon from '@/components/admin/settings/extra/patientUsers/PatientUserSelfLoginIcon'
import PatientUserStatusIcon from '@/components/admin/settings/extra/patientUsers/PatientUserStatusIcon'
import { STATUS_ACTIVE, STATUS_NOT_INVITED, STATUS_INVITATION_SENT, STATUS_INACTIVE, STATUS_PASSWORD_RESET, STATUS_STRINGS } from '@/data/userStatusValues'
import { CHANGE_STATUS } from '@/store/modules/clinicalUsers/constants'
import { PATIENT_ROW_ACTIVE } from '@/store/modules/applicationState/constants'
import { Helpers } from '@/mixins/Helpers'
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
  name: 'PatientUserStatusButton',
  components: { PatientUserSelfLoginIcon, PatientUserStatusIcon },
  mixins: [RespondentAccountActions, Helpers],
  props: ['accountData', 'userData'],
  data () {
    return {
      opened: false
    }
  },
  computed: {
    items () {
      const items = [{
        label: this.inviteLabel,
        method: this.sendInvite,
        show: this.showInviteToSelfLogin
      }, {
        label: this.$t('cancelInvite'),
        method: this.cancelinvite,
        show: this.showCancelInvite
      }, {
        label: this.$t('resetPasswordButtonText'),
        method: this.resetPassword,
        show: this.showResetPassword
      }, {
        label: this.$t('deactivate'),
        method: this.deactivate,
        show: this.showDeactivate
      }, {
        label: this.$t('activate'),
        method: this.activate,
        show: this.showActivate
      }]
      return items.filter(i => i.show)
    },
    inviteLabel () {
      return this.showActivate ? this.$t('inviteToSelfLoginAndInvite') : this.accountData.invitation_sent ? this.$t('inviteAgain') : this.$t('inviteToSelfLogin')
    },
    showInviteAgain () {
      return this.accountData.status === STATUS_INVITATION_SENT
    },
    showCancelInvite () {
      return this.accountData.status === STATUS_INVITATION_SENT
    },
    showInviteToSelfLogin () {
      return this.accountData.need_setup && this.userNotificationsEnabled(this.accountData) && this.accountData.status !== STATUS_ACTIVE
    },
    showResetPassword () {
      return this.userNotificationsEnabled(this.accountData) && this.accountData.status === STATUS_ACTIVE
    },
    showDeactivate () {
      return this.accountData.status !== STATUS_INACTIVE && this.userData.id !== 1
    },
    showActivate () {
      return this.accountData.status === STATUS_INACTIVE
    },
    keepOpenedClass () {
      return `keep_opened_${this._uid}`
    },
    user () {
      return {
        user_id: this.userData.id,
        contact_email: this.getEmailFromArray(this.userData.contact_information),
        contact_phone: this.getPhoneFromArray(this.userData.contact_information),
        first_name: this.userData.person_information.name_first,
        full_name: this.userData.person_information.full_name,
        is_clinical_user: this.accountData.is_clinical_user
      }
    },
    tooltipData () {
      let content = `<strong>${this.tooltipMainText}</strong><br>`
      const lastActivity = this.tooltipLastActivity
      content = lastActivity ? `${content} ${lastActivity}` : `${content}`
      return { content: content, placement: 'left', classes: ['patient-user-last-activity'] }
    },
    tooltipLastActivity () {
      if (!this.accountData.last_activity) {
        return ''
      }

      // Inactivated By.
      const date = moment.utc(this.accountData.last_activity.datetime).local().format('MMMM DD, YYYY')
      const info = this.accountData.last_activity.initiated_by_information
      if (this.accountData.status === STATUS_INACTIVE && info && info.full_name) {
        const lastActivity = this.$t('latest_activity_codes.deactivated_by', { name: info.full_name })
        return `${this.$t('lastActivity')}: ${lastActivity} ${date}`
      }

      // All others.
      const lastActivity = this.$t(`latest_activity_codes.value_${this.accountData.last_activity.activity_code}`, { name: this.user.first_name })
      return `${this.$t('lastActivity')}: ${lastActivity} ${date}`
    },
    tooltipMainText () {
      if (!this.accountData.is_clinical_user && !this.notificationsEnabled) {
        return this.$t('notificationsDisabled')
      }
      switch (this.accountData.status) {
        case STATUS_ACTIVE:
        case STATUS_PASSWORD_RESET:
          return `${this.$t('tooltipActiveAndCanSignIn')}`
        case STATUS_NOT_INVITED:
          return `${this.$t('tooltipActiveNoSignIn')}`
        case STATUS_INVITATION_SENT:
          const invitationDate = this.accountData.last_invitation_activity ? moment.utc(this.accountData.last_invitation_activity.datetime).fromNow() : '' // eslint-disable-line no-case-declarations
          return `${this.$t('tooltipActiveInvited', { date: invitationDate })}`
        case STATUS_INACTIVE:
          return `${this.$t('tooltipInactive')}`
        default:
          return ''
      }
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapMutations({
      setPatientRowActive: PATIENT_ROW_ACTIVE
    }),
    openSubmenu () {
      this.opened = !this.opened
    },
    handleClickOutside (evt) {
      if (evt.target && evt.target.classList && !evt.target.classList.contains(this.keepOpenedClass)) {
        this.opened = false
      }
    },
    cancelinvite () {
      this.changeStatus(STATUS_NOT_INVITED, this.$t('inviteCanceled'))
    },
    triggerActiveClassAndCallMethod (method) {
      this.setPatientRowActive(this.userData.id)
      method()
    },
    deactivate () {
      const promptOptions = {
        title: this.$t('deactivateName', { name: this.userData.person_information.full_name }),
        message: this.$t('deactivatePromptMessage'),
        cancelButton: this.$t('cancel'),
        okButton: this.$t('deactivate')
      }
      this.$promptBeforeAction(promptOptions, () => {
        this.setPatientRowActive(null)
        this.changeStatus(STATUS_INACTIVE, this.$t('deactivated'))
      }, () => {
        this.setPatientRowActive(null)
      })
    },
    activate () {
      this.changeStatus(STATUS_ACTIVE, this.$t('activated'))
    },
    changeStatus (status, str) {
      const params = {
        id: this.userData.id,
        status: status
      }
      const statusString = !str ? STATUS_STRINGS[params.status] : str
      this.$genericDispatch(CHANGE_STATUS, params).then(() => {
        this.$toast.success({ message: this.$t('userChangedStatus', { user: this.userData.person_information.full_name, status: statusString }) })
      })
    }

  }
}
</script>
